import { Configuration } from '@univearth/lifti-api/dist';

import customFetch from './customFetch';

const config = new Configuration({
  basePath: '/api/proxy',
  fetchApi: customFetch,
});

export default config;
