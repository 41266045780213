import { fetchAuthSession } from '@aws-amplify/auth';

/**
 * get access token from cookie
 * @returns access token
 */
export const getAccessToken = async () => {
  const { accessToken } =
    (
      await fetchAuthSession({
        forceRefresh: true,
      })
    ).tokens ?? {};
  return accessToken?.toString() ?? '';
};
